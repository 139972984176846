* {
    margin: 0;
}
html {
    font-size: 1vw;
}
body {
    min-height: 100vh;
    display: grid;
    place-items: center;
    background-color: #E5EFF6 !important;
}
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh !important;
    width: 100vw !important;
}
.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.container .main .form {
    flex: 1;
    width: 60%;
}
.container .main .button {
    width: 100%;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    margin: 1.5rem 0 3rem 0;
    font-size: 1.3rem;
    font-weight: bold;
}
.container .main .form .labelText {
    margin-top: 1rem;
    font-size: 1.1rem;
    margin-bottom: 2rem;
}
.container .main .form .textInput {
    font-size: 2rem;
}
.container .main h1 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
}
.link_button {
    width: 90%;
    background-color: #5C5C5C !important;
    padding: 1.5rem 1rem 1.5rem 1rem !important;
    font-size: 2rem;
    border-radius: 0.8rem !important;
    color: #FFFFFF;
    cursor: pointer;
}
.dfa_logo {
    position: absolute;
    top:10%;
    width: 9rem;
    height: auto;
    object-fit: contain;
    margin: 0 auto;
    display: block;
}

.phoneInput {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.countryCode {
    flex: 0.3;
    font-size: 2rem !important;
    margin-right: 1rem;
}

.phoneNumber {
    flex: 1;
    font-size: 2rem !important;
}

.react-tel-input .form-control {
    height: 3rem !important;
    font-size: 1.5rem !important;
}

.lottieText {
    font-size: 1.5rem;
    margin-top: 1rem;
}

@media screen and (max-width: 600px) {

    .container {
        margin: 0 auto !important;
        height: 100vh !important;
        width: 100vw !important;
        background-color: #E7F5F3;
    }
    .main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .container .main .form {
        position: absolute;
        top: 40%;
        flex: 1;
        width: 85% !important;
    }
    .container .main .button {
        width: 100%;
        padding-top: 2rem;
        padding-bottom: 2rem;
        margin: 3rem 0 6rem 0;
        font-size: 2.5rem;
        font-weight: bold;
    }
    .container .main .form .labelText {
        margin-top: 2rem;
        font-size: 5rem;
        margin-bottom: 3.5rem;
    }
    .container .main .form .textInput {
        font-size: 4.5rem;
        margin-bottom: 3.5rem;
    }
    .container .main h1 {
        margin-top: 2.5rem;
        margin-bottom: 2.5rem;
        font-size: 5rem !important;
    }
    .link_button {
        width: 90%;
        padding-top: 5rem !important;
        padding-bottom: 5rem !important;
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        margin-top: 2rem;
        font-size: 3.5rem !important;
        font-weight: bold;
        border-radius: 2rem;
        background-color: '#5C5C5C' !important;
        color: '#FFFFFF';
        cursor: pointer;
        border-radius: 2rem !important;
    }
    .dfa_logo {
        position: absolute !important;
        top: 12% !important;
        width: 35rem;
        height: auto;
        object-fit: contain;
        margin: 0 auto;
        display: block;
        margin-bottom: 15rem;
    }
    .countryCode {
        flex: 0.3;
        font-size: 5rem !important;
        margin-right: 1rem;
    }
    
    .phoneNumber {
        flex: 1;
        font-size: 5rem !important;
    }

    .phoneInput .label {
        font-size: 5rem !important;
    }

    .react-tel-input .form-control {
        height: 15rem !important;
        width: 100% !important;
        font-size: 6rem !important;
    }

    .swal2-popup {
        font-size: 3rem !important;
    }
    
    .swal2-styled {
        padding: 1rem 2rem 1rem 2rem !important;
        margin: 1rem 2rem 0rem 1rem !important;
    }

    .button {
        font-size: 6rem !important;
        border-radius: 2rem !important;
    }

    .lottieStyle {
        width: 60% !important;
    }

    .lottieAlign {
        display: flex;
        justify-content: center;
    }

    .lottieText {
        width: 80%;
        font-size: 4rem;
        margin-top: 2rem;
    }
}